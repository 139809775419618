import React, { FC } from 'react'
import Button from 'src/components/ui/Button'

interface IContactLocationBox {
  image: {
    url: string
    alt: string
  }
  addressFirstLine: string
  addressSecondLine: string
  addressThirdLine: string
  mapUrl: string
  mapLinkText: string
  className?: string
}

const ContactLocationBox: FC<IContactLocationBox> = ({
  image,
  addressFirstLine,
  addressSecondLine,
  addressThirdLine,
  mapUrl,
  mapLinkText,
  className,
}) => (
  <div
    className={`px-4 md:px-8 py-4 md:py-8 border-2 border-secondaryGray500 rounded-lg flex ${className}`}
  >
    <div className="w-5/12 md:w-1/2 flex flex-col mr-2">
      <img
        src={image.url}
        className="object-cover flex-1 rounded-xl w-full"
        alt={image.alt}
      />
    </div>
    <div className="w-7/12 text-center flex flex-wrap">
      <div className="self-start w-full">
        <h4 className="text-lg font-bold">{addressFirstLine}</h4>
        <p className="mt-2">{addressSecondLine}</p>
        <p className="mt-2">{addressThirdLine}</p>
      </div>
      <div className="mt-3 md:mt-4 self-end w-full">
        <a href={mapUrl} rel="noreferrer" target="_blank">
          <Button>{mapLinkText}</Button>
        </a>
      </div>
    </div>
  </div>
)

ContactLocationBox.defaultProps = {
  className: '',
}

export default ContactLocationBox
