// i18next-extract-mark-ns-start contact-page

import React, { FC, useState } from 'react'
import { graphql } from 'gatsby'
import Subtitle from 'src/components/ui/Subtitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import H2 from 'src/components/ui/title/H2'
import Button from 'src/components/ui/Button'
import Layout from 'src/components/layout'
import Select from 'src/components/ui/select'
import { ISelectOption } from 'src/components/ui/select/SelectOption'
import HighLight from 'src/components/sections/teaser/Highlight'
import { Email } from 'src/services/smtp'
import ContactActionButton from 'src/components/ui/contact/ContactActionButton'
import ContactLocationBox from 'src/components/ui/contact/ContactLocationBox'
import { directusFileUrl } from 'src/helpers/directus'

interface IContactPageProps {
  className?: string
  data: {
    directus: {
      pages: Array<{ translations: Array<{ content: string }> }>
    }
  }
}

const IS_WARSAW_ADDRESS_HIDDEN = true

const ContactPage: FC<IContactPageProps> = ({ className, data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }

  const [userContact, setUserContact] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSend, setIsSend] = useState(false)

  const { t } = useTranslation('contact-page')

  const CONTACT_OPTIONS: Array<ISelectOption> = [
    {
      optionId: 'product-dev',
      label: t('Product Development'),
    },
    {
      optionId: 'sport',
      label: t('Sport'),
    },
    {
      optionId: 'imagination',
      label: t('Imagination & Evaluation'),
    },
    {
      optionId: 'product-design',
      label: t('Product Design'),
    },
    {
      optionId: 'growth',
      label: t('Growth & Innovation'),
    },
    {
      optionId: 'tdc',
      label: t('Technology-driven Consultancy'),
    },
  ]

  const [selectedContactOption, selectContactOption] = useState(
    CONTACT_OPTIONS[0]
  )

  const cmsJson = JSON.parse(translation.content)

  const isFormValid = () => {
    if (isSending) {
      return false
    }
    if (userContact.length < 3) {
      return false
    }

    return true
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSending(true)

    Email.send({
      SecureToken: 'ca5d43a9-c776-4658-9313-a70232f75865 ',
      To: 'studio@mamf.pl',
      From: 'noreply@mamf.pl',
      Subject: `${t('Enquiry from the MAMF form, subject:')}' ${
        selectedContactOption.label
      }`,
      Body: `${t('New request for contact regarding')} ${
        selectedContactOption.label
      } ${t('from:')} ${userContact}`,
    })
      .then(() => {
        setUserContact('')
        setIsSend(true)
      })
      .finally(() => setIsSending(false))
  }

  const successMessagge = () => {
    if (isSend) {
      return t('Message has been send successfully!')
    }

    return ''
  }

  const handleUserContactChange = (e) => {
    setIsSend(false)
    setUserContact(e.target.value)
  }

  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <div className={className}>
        <section
          data-name={t('contact')}
          id={t('contact')}
          className="container flex flex-wrap mt-16 md:mt-28 py-10 md:py-20"
        >
          <div className="w-full lg:w-1/2 lg:pr-8 text-center lg:text-left">
            <Subtitle className="mb-2 md:mb-6 center">{t('subtitle')}</Subtitle>
            <H2
              firstPart={t('title-first-part')}
              secondPart={t('title-second-part')}
            />
            <p className="mt-12">{t('we were hoping')}</p>
            <div className="mt-16 flex flex-wrap justify-center lg:justify-start">
              <ContactActionButton
                regularContent={t('email us')}
                hoverContent={t('contact-email')}
                mobileHref={`mailto:${t('contact-email')}`}
                actionContent={t('contact-copied-text')}
                desktopOnClick={() =>
                  navigator.clipboard.writeText(t('contact-email'))
                }
                className="w-40 md:w-48 mx-2 sm:mx-3 lg:ml-0 lg:mr-4 mb-4"
              />
              <ContactActionButton
                regularContent={t('call us')}
                hoverContent={t('contact-phone-number')}
                mobileHref={`tel:${t('contact-phone-number')}`}
                actionContent={t('contact-copied-text')}
                desktopOnClick={() =>
                  navigator.clipboard.writeText(t('contact-phone-number'))
                }
                isOutline
                className="w-40 md:w-48 mx-2 sm:mx-3 lg:mx-0"
              />
            </div>
          </div>
          <form
            className="w-full lg:w-1/2 lg:pl-12 mt-12 lg:mt-0"
            onSubmit={onSubmit}
          >
            <div className="px-6 py-8 border-2 border-secondaryGray500 rounded-lg">
              <p className="text-secondaryGray800 text-center text-lg ">
                {t('Select interesting service')}
              </p>
              <p className="font-semibold text-sm mt-12 mb-1">{t('service')}</p>
              <Select
                className="w-full"
                placeholder={t('Select subject...')}
                onSelect={selectContactOption}
                selectedItem={selectedContactOption}
                options={CONTACT_OPTIONS}
              />
              <p className="font-semibold text-sm mt-6 mb-1">
                {t('your contact')}
              </p>
              <input
                required
                minLength={3}
                className="w-full px-4 py-4 border-2 border-secondaryGray600 rounded-lg"
                placeholder={t('your phone number or e-mail address')}
                value={userContact}
                onChange={handleUserContactChange}
              />
              <div className="mt-10">
                <p className="mb-2 text-lightGreen font-semibold font-lg center text-center">
                  {successMessagge()}
                </p>
                <Button
                  type="submit"
                  className="w-full"
                  isDisabled={!isFormValid()}
                >
                  {t('send')}
                </Button>
              </div>
            </div>
          </form>
        </section>
        <section
          data-name={t('visit us')}
          id={t('visit us')}
          className="container flex flex-wrap py-10 md:py-20"
        >
          <div className="w-full md:w-1/2 pr-8">
            <Subtitle className="mb-2 md:mb-6">{t('visit-us')}</Subtitle>
            <H2 firstPart={t('we-have')} secondPart={t('two-locations')} />
            <HighLight className="mt-12">
              <b>{t('you can find us in offices in Wroclaw and Warsaw')}</b>
            </HighLight>
          </div>
          <div className="w-full md:w-1/2 mt-12 md:pl-12 lg:mt-0 flex items-center">
            <ContactLocationBox
              image={{
                url: directusFileUrl(cmsJson.wroclawImageId),
                alt: t('Wroclaw office'),
              }}
              addressFirstLine={t('wroclaw-address-1')}
              addressSecondLine={t('wroclaw-address-2')}
              addressThirdLine={t('wroclaw-address-3')}
              mapUrl={cmsJson.wroclawMapUrl}
              mapLinkText={t('see on map')}
            />
            {!IS_WARSAW_ADDRESS_HIDDEN && (
              <ContactLocationBox
                image={{
                  url: directusFileUrl(cmsJson.warsawImageId),
                  alt: t('Warsaw office'),
                }}
                addressFirstLine={t('warszawa-address-1')}
                addressSecondLine={t('warszawa-address-2')}
                addressThirdLine={t('warszawa-address-3')}
                mapUrl={cmsJson.warsawMapUrl}
                mapLinkText={t('see on map')}
                className="mt-12"
              />
            )}
          </div>
        </section>
      </div>
    </Layout>
  )
}

ContactPage.defaultProps = {
  className: '',
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/contact" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
    }
  }
`

export default ContactPage
