import React, { FC, useEffect, useRef, useState } from 'react'
import { isSmScreen } from 'src/helpers/layout'
import styled from 'styled-components'

const StyledButton = styled.button`
  --smooth-corners: 2.1;
  div:first-child {
    visibility: hidden;
  }
  :hover {
    div:last-child {
      visibility: hidden;
    }
    div:first-child {
      visibility: visible;
    }
  }
`

const SHOW_ACTION_DURATION_MS = 1500

interface IContactActionButtonProps {
  regularContent: JSX.Element
  hoverContent: JSX.Element
  actionContent: JSX.Element
  mobileHref: string
  desktopOnClick: () => void
  className?: string
  isOutline?: boolean
}

const ContactActionButton: FC<IContactActionButtonProps> = ({
  regularContent,
  hoverContent,
  actionContent,
  mobileHref,
  desktopOnClick,
  className,
  isOutline,
}) => {
  const buttonMainStyles = `
    relative rounded-lg inline-block border-2 transition duration-500 ${className}`

  const outlineStyles =
    'text-red bg-white hover:bg-red hover:text-white border-red'
  const filledStyles =
    'text-white bg-red hover:bg-white hover:text-red border-red'

  const buttonTypeStyles = isOutline ? outlineStyles : filledStyles

  const buttonStyles = `${buttonMainStyles} px-3.5 md:px-7.5 pt-1 md:pt-3 pb-2 md:pb-4 leading-none font-semibold ${buttonTypeStyles}`

  const activeTimeout = useRef(null)
  const [showAction, setShowAction] = useState(false)

  const handleClick = (event) => {
    if (isSmScreen()) {
      if (activeTimeout.current != null) {
        clearTimeout(activeTimeout.current)
      }

      desktopOnClick()

      setShowAction(true)
      activeTimeout.current = setTimeout(() => {
        activeTimeout.current = null
        setShowAction(false)
      }, SHOW_ACTION_DURATION_MS)

      event.preventDefault()
    }
  }

  useEffect(
    () => () => {
      if (activeTimeout.current != null) {
        clearTimeout(activeTimeout.current)
        activeTimeout.current = null
      }
    },
    []
  )

  return (
    <a href={mobileHref} target="_blank" rel="noreferrer">
      <StyledButton
        type="button"
        className={buttonStyles}
        onClick={handleClick}
      >
        {showAction ? (
          actionContent
        ) : (
          <>
            <div
              className={`absolute inset-0 flex justify-center items-center ${
                isOutline ? 'text-white' : 'text-red'
              }`}
            >
              <span>{hoverContent}</span>
            </div>
            <div className="h-6" />
            <div
              className={`absolute inset-0 flex justify-center items-center ${
                isOutline ? 'text-red' : 'text-white'
              }`}
            >
              <span>{regularContent}</span>
            </div>
          </>
        )}
      </StyledButton>
    </a>
  )
}

ContactActionButton.defaultProps = {
  isOutline: false,
  className: '',
}

export default ContactActionButton
