import React, { FC } from 'react'

export interface ISelectOption {
  optionId: string
  label: string | JSX.Element | JSX.Element[]
}

interface ISelectOptionProps {
  option: ISelectOption
  onClick: () => any
  isSelected: boolean
  className?: string
}

const SelectOption: FC<ISelectOptionProps> = ({
  className,
  option,
  isSelected,
  onClick,
}) => (
  <li
    key={option.optionId}
    className={`${className} hover:bg-red hover:text-white transition duration-200`}
  >
    <button
      type="button"
      onClick={onClick}
      className={`px-4 py-2 block w-full text-left ${
        isSelected ? 'font-bold' : 'font-base'
      }`}
    >
      {option.label}
    </button>
  </li>
)

SelectOption.defaultProps = {
  className: '',
}

export default SelectOption
