import React, { FC, useEffect, useRef, useState } from 'react'
import SelectOption, {
  ISelectOption,
} from 'src/components/ui/select/SelectOption'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import angleBottomGray from 'src/images/svg/angle-bottom-gray.svg'

interface ISelectProps {
  className?: string
  options: ISelectOption[]
  selectedItem: ISelectOption | null
  onSelect: (option: ISelectOption) => any
  placeholder: string
}

const Select: FC<ISelectProps> = ({
  className,
  options,
  selectedItem,
  onSelect,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  const handleOptionClick = (option: ISelectOption) => {
    onSelect(option)
    setIsOpen(false)
  }

  const handleDocumentClick = (event) => {
    const isClickedOutside =
      selectRef.current && !selectRef.current.contains(event.target)

    if (isClickedOutside) {
      setIsOpen(false)
    }
  }

  const getOptions = () =>
    options.map((option) => (
      <SelectOption
        isSelected={selectedItem && selectedItem.optionId === option.optionId}
        key={option.optionId}
        option={option}
        onClick={() => handleOptionClick(option)}
      />
    ))

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <div
      className={`${className} border-2 border-secondaryGray600 rounded-lg relative`}
      ref={selectRef}
    >
      <button
        type="button"
        className="px-4 py-4 w-full text-left flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{(selectedItem && selectedItem.label) || placeholder}</span>
        <img
          src={angleBottomGray}
          alt="arrow"
          className={`transition transform mr-5 ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </button>
      <HeightAnimation
        isOpen={isOpen}
        className="absolute w-full mt-2 rounded-lg bg-white border-2 border-secondaryGray600"
        duration={0.25}
      >
        <ul className="max-h-64 overflow-y-auto">{getOptions()}</ul>
      </HeightAnimation>
    </div>
  )
}

Select.defaultProps = {
  className: '',
}

export default Select
